// import "./wdyr";
import { HydratedRouter } from "react-router/dom";
import "core-js/es/array/at";
import * as Sentry from "@sentry/react";
import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://ced7225793464af89bbd15844adf94c4@o169618.ingest.sentry.io/6608700",
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.PROD ? "production" : "development",
  enabled: import.meta.env.PROD,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.015,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    /Detected manifest version mismatch, reloading/
    // "AbortError: The user aborted a request",
    // "AbortError: Fetch is aborted",
    // "Fetch is aborted",
    // "query() call aborted", // I think this is just someone leaving a page in the middle of loading?
    // "queryRoute() call aborted", // I think this is just someone leaving a page in the middle of loading?
    // "Expected route module to be loaded for routes", // Happens when people click links multiple times, I don't think it actually causes user issues
    // "Error: Failed to fetch", // Tired of seeing it, never has useful info
    // "TypeError: Failed to fetch", // Tired of seeing it, never has useful info
    // "ResizeObserver loop limit exceeded", // See https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
    // "ResizeObserver loop completed with undelivered notifications", // See https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
    // "Object Not Found Matching Id", // MSFT Email checker script issue, see https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/23
    // "Unable to find DocumentOrShadowRoot for editor element",
    // "Cannot get the start point in the node at path", // Slate issues
    // "Cannot resolve a DOM point from Slate point", // Slate issues
    // "https://reactjs.org/docs/error-decoder.html?invariant=418", // Hydration failed because the initial UI does not match what was rendered on the server
    // "https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
    // "https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...,
    // "https://reactjs.org/docs/error-decoder.html?invariant=421", // Text content does not match server-rendered HTML...,
    // "This Suspense boundary received an update before it finished hydrating"
  ]
});

startTransition(() => {
  hydrateRoot(document, <HydratedRouter />);
});
